import React, { useEffect } from 'react';
import { dehydrate, QueryClient } from '@tanstack/react-query';
import Head from 'next/head';
import { api } from 'shared/api';
import { url } from 'shared/url';
import { useSubcategories } from 'entities/subcategory';
import { SavedImage } from 'shared/image';
import { useBanners } from 'entities/banner';
import { usePromoList } from 'entities/promo';
import { PageWrapper } from 'entities/auth';
import { sendMetrikaEvent } from 'entities/metrika';
import Index from '../src/pages/Index/Index';

type Props = {
  maleCover: SavedImage | null;
  femaleCover: SavedImage | null;
};

const IndexPage = ({ maleCover, femaleCover }: Props) => {
  const { data: { data: subcategories = [] } = {} } = useSubcategories({ staleTime: Infinity });
  const { data: { data: banners = [] } = {} } = useBanners({ staleTime: Infinity });
  const { data: { data: promoList = [] } = {} } = usePromoList({ staleTime: Infinity });
  const sectionSubcategories = subcategories.filter((item) => !item.gender);

  useEffect(() => {
    sendMetrikaEvent('visitIndexPage');
  }, []);

  return (
    <>
      <Head>
        <title>SI OPTICS &#8212; оптика на Новослободской</title>
        <link rel="canonical" href={process.env.SITE_URL} />
        <meta
          key="description"
          name="description"
          content="SI OPTICS — магазин оптики в Москве. У нас можно заказать высококачественные оправы и солнцезащитные очки по низким ценам. Ждем вас по адресу ул Новослободская, 57/65 (м. Новослободская и м. Менделеевская)"
        />
        <meta property="og:url" content={`${process.env.SITE_URL}`} />
        <meta property="og:image:width" content="525" />
        <meta property="og:image:height" content="500" />
        <meta property="og:image" content="/static/logo.jpg" />
        <meta property="og:title" content="Магазин очков для зрения и солнцезащитных очков" />
        <meta
          property="og:description"
          content="Подберем идеальные очки. Ждем вас по адресу ул. Новослободская, 57/65."
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `
             {
              "@context": "https://schema.org/",
              "@type": "BreadcrumbList",
              "itemListElement": [{
              "@type": "ListItem",
              "position": 1,
              "name": "Главная",
              "item": "https://www.sioptics.ru"
            }]
            }           
            `,
          }}
        />

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `
                     {
            "@context": "http://schema.org",
            "@type": "LocalBusiness",
            "address": {
            "@type": "PostalAddress",
            "addressLocality": "Москва",
            "streetAddress": "ул. Новослободская, 57/65"
          },
            "name": "SI OPTICS",
            "email": "contact@sioptics.ru",
             "openingHoursSpecification": [
               {
                 "@type": "OpeningHoursSpecification",
                 "dayOfWeek": [
                   "Monday",
                   "Tuesday",
                   "Wednesday",
                   "Thursday",
                   "Friday",
                   "Saturday"
                 ],
                 "opens": "11:00",
                 "closes": "20:30"
               }
             ],
            "priceRange": "$",
            "telephone": "+79911773386",
            "image": "https://sioptics-cover.s3.eu-central-1.amazonaws.com/android-chrome-512x512.png",
            "url": "https://www.sioptics.ru",
            "geo": {
                "@type": "GeoCoordinates",
                "latitude": "55.78807",
                "longitude": "37.59187"
            }
          }`,
          }}
        />
      </Head>
      <PageWrapper>
        <Index
          maleCover={maleCover}
          femaleCover={femaleCover}
          subcategories={sectionSubcategories}
          banners={banners}
          promoList={promoList}
        />
      </PageWrapper>
    </>
  );
};

export const getStaticProps = async () => {
  const queryClient = new QueryClient();

  await queryClient.prefetchQuery(['subcategories'], () =>
    api.get(url('/api/subcategory')).catch(() => Promise.resolve({ data: [] }))
  );

  await queryClient.prefetchQuery(['banners'], () =>
    api.get(url('/api/banner')).catch(() => Promise.resolve({ data: [] }))
  );

  await queryClient.prefetchQuery(['promoList'], () =>
    api.get(url('/api/promo')).catch(() => Promise.resolve({ data: [] }))
  );

  let male: SavedImage | undefined;
  let female: SavedImage | undefined;

  try {
    male = (await api.get<SavedImage>(url('/api/cover?gender=male'))).data;
    female = (await api.get<SavedImage>(url('/api/cover?gender=female'))).data;
  } catch (e) {
    console.error(e);
  }
  
  return {
    props: {
      dehydratedState: dehydrate(queryClient),
      maleCover: male ?? null,
      femaleCover: female ?? null,
    },
    revalidate: 100,
  };
};

export default IndexPage;
